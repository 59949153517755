import React, { useEffect } from "react";

//updated domain to 2.0.7
const androidUrl = "https://download.shangshispace.com/pingFan.2.0.7.b.153.apk";
const oldAndroidUrl = androidUrl;
const iosUrl = "https://apps.apple.com/us/app/pingfansanyi/id1631082055";
const macUrl = "https://download.shangshispace.com/Pingfan.1.5.0.b1.dmg";

function isWeixin() {
  return navigator.userAgent.toLowerCase().indexOf("micromessenger") > -1;
}

function getMobileOperatingSystem() {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Android
  if (/android|Linux/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  // Mac & iPad pro
  if (userAgent.toUpperCase().indexOf("MAC") >= 0) {
    if (navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
      return "iOS";
    }
    return "Mac";
  }

  return "unknown";
}

function getAndroidVersion(ua) {
  ua = (ua || navigator.userAgent).toLowerCase();
  let match = ua.match(/android\s([0-9\.]*)/i);
  return match ? match[1] : undefined;
}

let deviceType = getMobileOperatingSystem();

const redirectToDownloadUrl = () => {
  // copy deeplink url into clipboard
  // navigator.clipboard.writeText(deepLinkUrl);

  // redirect to download URL
  if (deviceType == "iOS") {
    window.location.href = iosUrl;
  } else if (deviceType == "Mac") {
    window.location.href = macUrl;
  } else {
    let androidVersion = getAndroidVersion();
    if (androidVersion) {
      let androidVersionNum = parseInt(getAndroidVersion(), 10);
      if (androidVersionNum > 5) {
        window.location.href = androidUrl;
      } else {
        window.location.href = oldAndroidUrl;
      }
    } else {
      window.location.href = androidUrl;
    }
  }
};

const DownloadPingfan = () => {
  const isInWexin = isWeixin();

  const initializePage = () => {
    // title
    document.title = "平凡APP";
    const logoUrl = "/images/pingfan/favicon.jpg";

    // favicon
    let link = document.querySelector("link[rel~='icon']");
    if (link) {
      link.href = logoUrl;
    }

    // apple touch icon
    let linkApple = document.querySelector("link[rel~='apple-touch-icon']");
    if (linkApple) {
      linkApple.href = logoUrl;
    }
  };

  const startDownloading = () => {
    if (!isInWexin) {
      // open deep link
      let urlArr = window.location.href.split("downloadpingfan");
      // get deep link params
      if (urlArr.length > 1 && urlArr[1] !== "" && urlArr[1] !== "/") {
        let deepLinkParams = "/";
        deepLinkParams = urlArr[1];
        if (deepLinkParams.substr(0, 1) !== "/") {
          deepLinkParams = "/" + deepLinkParams;
        }
        let deepLinkUrl = "pingfansanyi:/" + deepLinkParams;
        window.location = deepLinkUrl;

        // open download url if app not installed
        setTimeout(function () {
          redirectToDownloadUrl();
        }, 2000);
      } else {
        redirectToDownloadUrl();
      }
    }
  };

  // This effect runs once, after the first render
  useEffect(() => {
    initializePage();
    startDownloading();
  }, []);

  return (
    <div className="download-body pingfan">
      {isInWexin && (
        <img
          src="/images/pingfan/wechat-click-arrow.svg"
          loading="lazy"
          alt=""
          className="wechat-click-arrow"
        />
      )}
      <div className="download-content-wrapper">
        <img
          src={
            isInWexin
              ? "/images/pingfan/wechat-click-content.svg"
              : "/images/pingfan/android-download-content.svg"
          }
          loading="lazy"
          alt=""
          className="download-content"
        />
      </div>
    </div>
  );
};

export default DownloadPingfan;
